<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="12">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.doctor_group"
                      :rules="rules.text100Required"
                      type="text"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'MÉDICOS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar..."
                      single-line
                      hide-details
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Especialidad*"
                      v-model="specialty_type_id"
                      :items="specialty_types"
                      item-value="id"
                      :item-text="(i) => i.specialty_type"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Estado*"
                      v-model="entity_id"
                      :items="entities"
                      item-value="id"
                      :item-text="(i) => i.entity"
                      :loading="entities_loading"
                      v-on:change="getMunicipalities()"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Municipio*"
                      v-model="municipality_id"
                      :items="municipalities"
                      item-value="id"
                      :item-text="(i) => i.municipality"
                      :loading="municipalities_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="12">
                    <v-data-table
                      v-model="data.doctor_in_groups"
                      :headers="headers"
                      :items="filterDoctors"
                      :single-select="false"
                      item-key="id"
                      :search="search"
                      :loading="loading_doctors"
                      show-select
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      singleSelect: false,
      page_route: "redes.medicos",
      //vars control
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        doctor_group: "",
        doctor_in_groups: [],
      },
      group: [],
      search: "",
      selected: [],
      headers: [
        { text: "Médico", filterable: true, value: "full_name" },
        { text: "Especialidad", filterable: true, value: "specialty_type" },
        { text: "Estado", filterable: true, value: "entity" },
        { text: "Municipio", filterable: true, value: "municipality" },
      ],
      rules: rules(),
      loading_doctors: true,
      specialty_type_id: null,
      entity_id: null,
      municipality_id: null,
      specialty_types: [],
      entities: [],
      municipalities: [],
      municipalities_loading: false,
      entities_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getMunicipalities() {
      this.municipality_id = null;

      if (this.entity_id) {
        this.municipalities_loading = true;

        index("municipalities/" + this.entity_id, this.login.token, []).then(
          (response) => {
            this.municipalities = response.data;
            this.defaultMunicipalities();
            this.municipalities_loading = false;
          }
        );
      } else {
        this.municipalities = [];
        this.defaultMunicipalities();
      }
    },
    defaultMunicipalities() {
      this.municipalities.push({
        id: null,
        municipality: "TODOS",
        active: 1,
        entity_id: this.entity_id,
      });
    },
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              storeUpdate(
                "doctor/groups",
                this.login.token,
                this.id,
                this.data,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({
                      name: `${this.page_route}${
                        this.store_mode ? "" : ".view"
                      }`,
                      params: { id: this.id },
                    })
                  : console.log(response.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
  },
  computed: {
    filterDoctors() {
      return this.group.filter((provider) => {
        let condition1 = this.specialty_type_id
          ? provider.specialty_type_id === this.specialty_type_id
          : true;

        let condition2 = this.entity_id
          ? provider.entity_id === this.entity_id
          : true;

        let condition3 = this.municipality_id
          ? provider.municipality_id === this.municipality_id
          : true;

        return condition1 && condition2 && condition3;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    catalog("entities", this.login.token).then((response) => {
      this.entities = response;
      this.entities.push({
        id: null,
        entity: "TODOS",
        abbreviation: "TODOS",
        active: 1,
      });

      this.entity_id = null;
      this.entities_loading = false;
    });

    catalog("specialty_types", this.login.token).then((response) => {
      this.specialty_types = response;
      this.specialty_types.push({
        id: null,
        specialty_type: "TODOS",
        active: 1,
      });
    });

    index("doctors", this.login.token, [
      {
        name: "filter",
        value: 1,
      },
    ]).then((response) => {
      this.group = response.data;
      this.loading_doctors = false;
    });

    if (!this.store_mode) {
      this.loading = true;

      show("doctor/groups", this.login.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
      });
    }

    this.defaultMunicipalities();
  },
};
</script>